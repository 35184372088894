<template>
    <yh-page fullscreen lighting nav-top="24px">
        <div class="banners">
            <div ref="index-swiper" class="swiper-container">
                <div class="swiper-wrapper">

                    <div class="swiper-slide"
                         style="background-image: url(https://static.ycsd.work/assets/images/colbg.png)"></div>
                    <div class="swiper-slide"
                         style="background-image: url(https://static.ycsd.work/assets/images/colbg.png)"></div>
                    <div class="swiper-slide"
                         style="background-image: url(https://static.ycsd.work/assets/images/colbg.png)"></div>

                </div>

                <div class="swiper-pagination"></div>

            </div>
        </div>

        <main-wrapper>

            <div class="index-item">
                <div class="title">专题精选</div>

                <div class="content">

                    <div class="home-cards">
                        <div class="left">
                            <div class="card-img">
                                <img src="/assets/images/home/zt/1.png" alt="">
                                <div class="card-title">#聚会怎么穿</div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="card-img">
                                <img src="/assets/images/home/zt/2.png" alt="">
                                <div class="card-title">#同学会怎么穿</div>
                            </div>
                            <div class="card-img">
                                <img src="/assets/images/home/zt/3.png" alt="">
                                <div class="card-title">#情侣约会怎么穿</div>
                            </div>
                        </div>
                    </div>

                    <div class="home-cards">
                        <div class="left">
                            <div class="card-img">
                                <img src="/assets/images/home/zt/4.png" alt="">
                                <div class="card-title">#春日出游怎么穿</div>
                            </div>
                            <div class="card-img">
                                <img src="/assets/images/home/zt/5.png" alt="">
                                <div class="card-title">#海边度假怎么穿</div>
                            </div>
                        </div>
                        <div class="right">
                            <div class="card-img">
                                <img src="/assets/images/home/zt/6.png" alt="">
                                <div class="card-title">#野餐怎么穿</div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div class="index-item">
                <div class="title">搭配精选</div>

            </div>


        </main-wrapper>


    </yh-page>
</template>

<script>

    import YhPage from "../../layout/YhPage";
    import 'swiper/swiper-bundle.min.css'
    import Swiper from 'swiper/swiper-bundle.min'
    import MainWrapper from "../../layout/MainWrapper";

    export default {
        name: "Home",
        components: {MainWrapper, YhPage},
        data() {
            return {
                swiperOptions: {
                    direction: 'horizontal',
                    autoplay: {
                        delay: 5000,
                    },
                    // mousewheel: true,
                    loop: true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    }
                },
            }
        },
        mounted() {
            this.initSwiper()
        },
        methods: {
            initSwiper() {
                this.swiper = new Swiper(this.$refs['index-swiper'], this.swiperOptions);
            },
        }
    }
</script>

<style lang="less" scoped>

    .banners {
        background-color: rgba(16, 16, 16, 1);
        height: 100vh;
        overflow: hidden;
        position: relative;

        .swiper-container {
            height: 100%;
        }

        .swiper-slide {
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            /*background-color: white;*/
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
        }

        .swiper-pagination {
            right: 100px;
        }

        /deep/ .swiper-pagination-bullet {
            width: 10px;
            height: 10px;
            background: #D8D8D8;
            border-radius: 50%;

        }

        /deep/ .swiper-pagination-bullet-active {
            background-color: white;
        }
    }

    .index-item {
        margin-top: 120px;

        .title {
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            line-height: 33px;
            text-align: center;
            margin-bottom: 55px;
        }
    }

    .home-cards {
        overflow: hidden;

        .left, .right {
            width: 50%;
            float: left;
        }

        .left {
            padding-right: 16px;
        }

        .right {
            padding-left: 16px;
        }

        .card-img {
            margin-bottom: 32px;
            line-height: 0;
            background-color: #dddddd;
            position: relative;

        }

        .card-title {
            height: 98px;
            background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 33px;
            padding-left: 20px;
            display: flex;
            align-items: center;
            transition: all .3s;
        }

        .card-img:hover {
            .card-title {
                background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, rgba(0, 0, 0, 0.9) 100%);
            }
        }
    }

</style>
<template>
    <div :class="{fullscreen,'lighting':isLighting}">
        <div class="nav-bar" :style="{'top':top}">

            <div class="logo-container">
                <router-link class="logo" to="/" @click="gotoMain">帮搭</router-link>

                <router-link to="/gender" class="gender">{{ gender === 1 ? '男士':'女士' }} <span
                        class="iconfont pc-xiajiantou"></span></router-link>
            </div>
            <div v-if="!hideItems" class="nav-items">

                <template v-for="(item,k) in items">

                    <router-link
                            :to="item.route"
                            class="nav-item"
                            :class="{'active' :item.active}" :key="k">{{
                        item.name }}
                    </router-link>

                </template>

            </div>

            <div class="nav-right">
                <template v-if="info">
                    <div class="user-info">
                        <div class="avatar">
                            <img :src="info.setting.avatar" alt="">
                        </div>
                        <span>{{ info.setting.name }}</span>
                    </div>

                    <div class="menu">
                        <l-a href="/dps/collection" class="menu-item">我的收藏</l-a>

                        <!--                        <l-a href="/dps/outfits" class="menu-item">自购订单</l-a>-->

                        <template v-if="info.group_id === 3">
                            <l-a href="/ast/xcx/base" class="menu-item">主理人后台</l-a>
                        </template>

                        <div @click="logOut" class="menu-item">退出登录</div>
                    </div>

                    <router-link v-if=" info.setting.is_approve === 1 " class="start-dps" to="/dps/deck">开始搭配
                    </router-link>

                </template>
                <template v-else-if=" info === false ">
                    <router-link class="start-dps" to="/login">登录</router-link>
                </template>
            </div>


        </div>

        <template v-if="fullscreen">
            <slot/>
        </template>
        <template v-else>
            <main-wrapper>
                <slot/>
            </main-wrapper>
        </template>
    </div>
</template>

<script>

    import Vue from 'vue';
    import auth from "../auth";
    import MainWrapper from "./MainWrapper";
    import LA from "../components/LA";

    export default {
        name: "YhPage",
        components: {LA, MainWrapper},
        props: {
            validateGender: {
                type: Boolean,
                default() {
                    return true
                }
            },
            fullscreen: Boolean,
            navMode: {
                type: String,
                default() {
                    return 'normal';
                }
            },
            lighting: Boolean,
            hideItems: Boolean,
            current: String,
            navTop: {
                type: String,
                default() {
                    return '0'
                }
            }
        },
        data() {
            return {
                items: [
                    {
                        'name': '首页',
                        'route': '/'
                    },
                    {
                        'name': '单品选购',
                        'route': '/goods',
                    },
                    {
                        'name': '搭配选购',
                        'route': '/collections'
                    }
                ],
                info: null,
                top: this.navTop,
                isLighting: this.lighting,
                gender: auth.gender()
            }
        },
        mounted() {
            this.init()
            this.checkGender()
            this.listen()
        },
        watch: {
            navTop() {
                this.top = this.navTop
            }
        },
        destroyed() {
            this.unListen()
        },
        methods: {
            listen() {
                window.addEventListener('scroll', this.getScroll);
            },
            unListen() {
                window.removeEventListener('scroll', this.getScroll);
            },
            getScroll() {
                let scroll = document.documentElement.scrollTop;
                let top = parseInt(this.navTop)

                top = top - scroll;

                if (scroll <= top) {

                    this.top = this.navTop
                    this.isLighting = this.lighting

                    return
                }

                this.top = top > 0 ? top + 'px' : 0;

                this.isLighting = top > 0;

            },
            checkGender() {
                if (!this.validateGender) return;

                if (auth.gender()) return

                // this.$router.push('/gender')
                this.$router.push('/ast/xcx/base')
            },
            gotoMain(e) {
                e.preventDefault();

                this.$router.push('/')
            },
            gotoPage(e, item) {
                e.preventDefault();

                if ((item.route === this.$route.path) && !location.search) {
                    location.reload()
                    return
                }

                this.$router.push(item.route)

            },
            init() {

                auth.check((res) => {
                    this.info = res ? auth.userInfo() : false

                    if (!this.info) {
                        this.$router.push('/login')
                    }

                    if (this.info && (this.info.group_id === 6 || this.info.level === 3)) {
                        this.$router.push('/login')
                    }
                })

                this.activeNav()
            },
            logOut() {
                if (!confirm('确定要退出登录吗？')) return

                auth.loginOut()

                location.href = '/'
            },
            activeNav() {
                let path = this.current || this.$route.path;

                this.items.forEach(item => {
                    Vue.set(item, 'active', (item.route === path))
                })

            }
        }
    }
</script>

<style lang="less" scoped>
    .logo {
        text-decoration: none;
    }

    .nav-items {
        display: flex;
    }

    .user-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        height: 100%;
        z-index: 2;
    }

    .nav-item {
        color: rgba(51, 51, 51, 1);
        margin-right: 64px;
        cursor: pointer;
        position: relative;
        text-decoration: none;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            color: black;
        }

        &.active:after {
            content: ' ';
            width: 4px;
            height: 4px;
            position: absolute;
            bottom: -8px;
            left: 50%;
            background: #333333;
            border-radius: 50%;
            margin-left: -2px;
        }
    }

    .nav-bar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 64px;
        padding-left: 64px;
        padding-right: 64px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: 14px;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
        background-color: white;
        z-index: 990;

        transition: all .3s;
    }

    .avatar {
        width: 24px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 8px;

        img {
            width: 24px;
            height: 24px;
        }
    }

    .nav-right {
        min-width: 260px;
    }

    .user-info:hover + .menu, .menu:hover {
        opacity: 1;
        top: 64px;
    }

    .menu {
        background: #FFFFFF;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        opacity: 0;
        transition: all .3s;
        z-index: 1;
    }

    .menu-item {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        cursor: pointer;
        padding: 10px 28px 10px 16px;
    }


    .nav-gender {
        top: 24px;

        .nav-items {
            display: none;
        }
    }


    .logo, .gender {
        color: inherit;
    }

    .lighting {

        .nav-bar {
            background-color: transparent;
            box-shadow: none;
        }

        .gender {
            color: #FFFFFF;
        }


        .logo {
            color: #FFFFFF;
        }

        .user-info span {
            color: #FFFFFF;
        }

        .nav-item {
            &.active:after {
                background: white;
            }
        }

        .nav-items a {
            color: #FFFFFF;
        }

        .start-dps {
            color: #FFFFFF;
            border-color: #FFFFFF;
        }
    }

    .gender {
        margin-left: 55px;
        text-decoration: none;

        cursor: pointer;

        .iconfont {
            font-size: 12px;
            margin-left: 8px;
        }
    }

    .logo-container, .nav-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        height: 100%;
    }

    .start-dps {
        margin-left: 25px;

        border: 1px solid #333333;
        width: 96px;
        height: 32px;
        text-decoration: none;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        color: #333333;
        font-weight: 400;
    }

</style>
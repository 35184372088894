<template>
    <div class="content-wrapper">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "MainWrapper"
    }
</script>

<style scoped>
    .content-wrapper {
        width: 1312px;
        padding-top: 104px;
        margin: 0 auto;
    }
</style>